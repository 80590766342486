<template>
    <div data-elementor-type="wp-page" data-elementor-id="1489" class="elementor elementor-1489"
        data-elementor-post-type="page">
        <NoticeBan :title="title"></NoticeBan>
        <section style="background-color: #fff;"
            class="elementor-section elementor-top-section elementor-element elementor-element-7e5c0fc2 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="7e5c0fc2" data-element_type="section">
            <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6dbd9794"
                    data-id="6dbd9794" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                        <div class="elementor-element elementor-element-866c380 animated-slow elementor-widget elementor-widget-heading"
                            data-id="866c380" data-element_type="widget"
                            data-settings="{&quot;_animation&quot;:&quot;none&quot;}"
                            data-widget_type="heading.default">
                            <div class="elementor-widget-container">
                                <h5 class="elementor-heading-title elementor-size-default">
                                    {{ $t("newest.home_tip36") }}
                                </h5>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-0873c55 elementor-widget elementor-widget-heading"
                            data-id="0873c55" data-element_type="widget" data-widget_type="heading.default">
                            <div class="elementor-widget-container">
                                <h2 class="elementor-heading-title elementor-size-default">
                                    {{ $t("newest.home_tip37") }}

                                </h2>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-d2b8caa elementor-widget elementor-widget-text-editor"
                            data-id="d2b8caa" data-element_type="widget" data-widget_type="text-editor.default">
                            <div class="elementor-widget-container">
                                <p> {{ $t("newest.part_tip2") }}</p>
                                <p> {{ $t("newest.part_tip3") }}</p>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-87bd00c animated-slow elementor-mobile-align-center elementor-widget elementor-widget-button animated fadeIn"
                            data-id="87bd00c" data-element_type="widget"
                            data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:500}"
                            data-widget_type="button.default">
                            <div class="elementor-widget-container">
                                <div class="elementor-button-wrapper" @click="goTo('/about-us/contact')">
                                    <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-float"
                                         >
                                        <span class="elementor-button-content-wrapper" >
                                            <span class="elementor-button-text">{{ $t("newest.home_tip49") }}</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-32ea1abc"
                    data-id="32ea1abc" data-element_type="column"
                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <div class="elementor-widget-wrap elementor-element-populated">
                        <section
                            class="elementor-section elementor-inner-section elementor-element elementor-element-68985ca elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="68985ca" data-element_type="section">
                            <div class="elementor-container elementor-column-gap-default">
                                <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d2e7818"
                                    data-id="d2e7818" data-element_type="column">
                                    <div class="elementor-widget-wrap elementor-element-populated">
                                        <div class="elementor-element elementor-element-250c3d3 elementor-view-stacked elementor-shape-square elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                                            data-id="250c3d3" data-element_type="widget"
                                            data-widget_type="icon-box.default">
                                            <div class="elementor-widget-container">
                                                <link rel="stylesheet"
                                                    href="..\wp-content\plugins\elementor\assets\css\widget-icon-box.min.css">
                                                <div class="elementor-icon-box-wrapper">

                                                    <div class="elementor-icon-box-icon">
                                                        <span class="elementor-icon elementor-animation-">
                                                            <i aria-hidden="true" class="icon icon-save-money"></i>
                                                        </span>
                                                    </div>

                                                    <div class="elementor-icon-box-content">

                                                        <h3 class="elementor-icon-box-title">
                                                            <span>{{ $t("newest.home_tip38") }}
                                                            </span>
                                                        </h3>


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-130b57c"
                                    data-id="130b57c" data-element_type="column">
                                    <div class="elementor-widget-wrap elementor-element-populated">
                                        <div class="elementor-element elementor-element-76974a8 elementor-view-stacked elementor-shape-square elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                                            data-id="76974a8" data-element_type="widget"
                                            data-widget_type="icon-box.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-icon-box-wrapper">

                                                    <div class="elementor-icon-box-icon">
                                                        <span class="elementor-icon elementor-animation-">
                                                            <i aria-hidden="true" class="hm hm-dollar-box"></i> </span>
                                                    </div>

                                                    <div class="elementor-icon-box-content">

                                                        <h3 class="elementor-icon-box-title">
                                                            <span>{{ $t("newest.home_tip40") }}
                                                            </span>
                                                        </h3>


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            class="elementor-section elementor-inner-section elementor-element elementor-element-d858a0a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="d858a0a" data-element_type="section">
                            <div class="elementor-container elementor-column-gap-default">
                                <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-88985c0"
                                    data-id="88985c0" data-element_type="column">
                                    <div class="elementor-widget-wrap elementor-element-populated">
                                        <div class="elementor-element elementor-element-8242c55 elementor-view-stacked elementor-shape-square elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                                            data-id="8242c55" data-element_type="widget"
                                            data-widget_type="icon-box.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-icon-box-wrapper">

                                                    <div class="elementor-icon-box-icon">
                                                        <span class="elementor-icon elementor-animation-">
                                                            <i aria-hidden="true" class="icon icon-management"></i>
                                                        </span>
                                                    </div>

                                                    <div class="elementor-icon-box-content">

                                                        <h3 class="elementor-icon-box-title">
                                                            <span>{{ $t("newest.home_tip42") }}
                                                            </span>
                                                        </h3>


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-119e483"
                                    data-id="119e483" data-element_type="column">
                                    <div class="elementor-widget-wrap elementor-element-populated">
                                        <div class="elementor-element elementor-element-ed01ccf elementor-view-stacked elementor-shape-square elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                                            data-id="ed01ccf" data-element_type="widget"
                                            data-widget_type="icon-box.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-icon-box-wrapper">

                                                    <div class="elementor-icon-box-icon">
                                                        <span class="elementor-icon elementor-animation-">
                                                            <i aria-hidden="true" class="hm hm-headset"></i> </span>
                                                    </div>

                                                    <div class="elementor-icon-box-content">

                                                        <h3 class="elementor-icon-box-title">
                                                            <span>{{ $t("newest.home_tip47") }}
                                                            </span>
                                                        </h3>


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
        <section
            class="elementor-section elementor-top-section elementor-element elementor-element-61e8b681 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="61e8b681" data-element_type="section"
            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
            <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-12dcbcc0"
                    data-id="12dcbcc0" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                        <div class="elementor-element elementor-element-3ae3c5af animated-slow elementor-widget elementor-widget-heading"
                            data-id="3ae3c5af" data-element_type="widget"
                            data-settings="{&quot;_animation&quot;:&quot;none&quot;}"
                            data-widget_type="heading.default">
                            <div class="elementor-widget-container">
                                <h5 class="elementor-heading-title elementor-size-default">
                                    {{ $t("newest.part_tip5") }}
                                    &ZeroWidthSpace;</h5>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-3b87d27b elementor-widget elementor-widget-heading"
                            data-id="3b87d27b" data-element_type="widget" data-widget_type="heading.default">
                            <div class="elementor-widget-container">
                                <h2 class="elementor-heading-title elementor-size-default">
                                    {{ $t("newest.part_tip6") }}
                                    &ZeroWidthSpace;</h2>
                            </div>
                        </div>
                        <section
                            class="elementor-section elementor-inner-section elementor-element elementor-element-f901867 elementor-section-full_width animated-slow elementor-section-height-default elementor-section-height-default animated fadeInUp"
                            data-id="f901867" data-element_type="section"
                            data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:40}">
                            <div class="elementor-container elementor-column-gap-no">
                                <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1d53a94"
                                    data-id="1d53a94" data-element_type="column">
                                    <div class="elementor-widget-wrap elementor-element-populated">
                                        <div class="elementor-element elementor-element-ce7ab40 ekit-equal-height-enable elementor-widget elementor-widget-elementskit-icon-box"
                                            data-id="ce7ab40" data-element_type="widget"
                                            data-widget_type="elementskit-icon-box.default">
                                            <div class="elementor-widget-container">
                                                <div class="ekit-wid-con"> <!-- link opening -->
                                                    <!-- end link opening -->

                                                    <div
                                                        class="elementskit-infobox text-center text- icon-lef-right-aligin elementor-animation-float   ">
                                                        <div class="box-body">
                                                            <h3 class="elementskit-info-box-title">
                                                                {{ $t("newest.part_tip7") }} </h3>
                                                            <p>{{ $t("newest.part_tip8") }} </p>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-7074c73"
                                    data-id="7074c73" data-element_type="column">
                                    <div class="elementor-widget-wrap elementor-element-populated">
                                        <div class="elementor-element elementor-element-fc57b82 ekit-equal-height-enable elementor-widget elementor-widget-elementskit-icon-box"
                                            data-id="fc57b82" data-element_type="widget"
                                            data-widget_type="elementskit-icon-box.default">
                                            <div class="elementor-widget-container">
                                                <div class="ekit-wid-con"> <!-- link opening -->
                                                    <!-- end link opening -->

                                                    <div
                                                        class="elementskit-infobox text-center text- icon-lef-right-aligin elementor-animation-float   ">
                                                        <div class="box-body">
                                                            <h3 class="elementskit-info-box-title">
                                                                {{ $t("newest.part_tip9") }} </h3>
                                                            <p> {{ $t("newest.part_tip10") }}</p>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c472a21"
                                    data-id="c472a21" data-element_type="column">
                                    <div class="elementor-widget-wrap elementor-element-populated">
                                        <div class="elementor-element elementor-element-969e8f7 ekit-equal-height-enable elementor-widget elementor-widget-elementskit-icon-box"
                                            data-id="969e8f7" data-element_type="widget"
                                            data-widget_type="elementskit-icon-box.default">
                                            <div class="elementor-widget-container">
                                                <div class="ekit-wid-con"> <!-- link opening -->
                                                    <!-- end link opening -->

                                                    <div
                                                        class="elementskit-infobox text-center text- icon-lef-right-aligin elementor-animation-float   ">
                                                        <div class="box-body">
                                                            <h3 class="elementskit-info-box-title">
                                                                {{ $t("newest.part_tip11") }} </h3>
                                                            <p>{{ $t("newest.part_tip12") }}</p>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
        <section style="display:none;background-color: #fff;"
            class="elementor-section elementor-top-section elementor-element elementor-element-742915f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="742915f" data-element_type="section">
            <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ad4083b"
                    data-id="ad4083b" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                        <div class="elementor-element elementor-element-2df2afe animated-slow elementor-widget elementor-widget-heading"
                            data-id="2df2afe" data-element_type="widget"
                            data-settings="{&quot;_animation&quot;:&quot;none&quot;}"
                            data-widget_type="heading.default">
                            <div class="elementor-widget-container">
                                <h5 class="elementor-heading-title elementor-size-default">{{ $t("newest.part_tip1") }}
                                </h5>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-257c77f elementor-widget elementor-widget-heading"
                            data-id="257c77f" data-element_type="widget" data-widget_type="heading.default">
                            <div class="elementor-widget-container">
                                <h2 class="elementor-heading-title elementor-size-default">{{ $t("newest.part_tip4") }}
                                </h2>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-6e29403 elementor-widget elementor-widget-shortcode"
                            data-id="6e29403" data-element_type="widget" data-widget_type="shortcode.default">
                            <div class="elementor-widget-container">

                                <div class="elementor-shortcode">
                                    <div data-calc-id="17893" class="calculator-settings ccb-front ccb-wrapper-17893 ">
                                        <div class="calc-container-wrapper">
                                            <div data-calc-id="17893" class="calc-container vertical"><!---->
                                                <div class="ccb-demo-box-styles">
                                                    <div class="ccb-box-styles">
                                                        <div class="ccb-box-style-inner"><i
                                                                class="ccb-icon-Union-27"></i> <span>Two columns</span>
                                                        </div>
                                                        <div class="ccb-box-style-inner ccb-style-active"><i
                                                                class="ccb-icon-Union-26"></i> <span>Vertical</span>
                                                        </div>
                                                        <div class="ccb-box-style-inner"><i
                                                                class="ccb-icon-Union-25"></i> <span>Horizontal</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="calc-fields calc-list calc-list__indexed loaded">
                                                    <div class="calc-list-inner">
                                                        <div class="calc-item-title">
                                                            <div class="ccb-calc-heading">Rebate Calculator</div>
                                                        </div>
                                                        <div class="calc-fields-container">
                                                            <div data-id="dropDown_field_id_3"
                                                                class="calc-item ccb-field" format="" text-days="days"
                                                                id="17893" converter="function () { [native code] }">
                                                                <div class="calc-item__title"><span>Acount Type</span>
                                                                    <!---->
                                                                </div>
                                                                <div class="calc-item__description before"><span></span>
                                                                </div>
                                                                <div class="ccb-drop-down calc_dropDown_field_id_3">
                                                                    <div class="calc-drop-down">
                                                                        <div class="calc-drop-down-wrapper"><span
                                                                                data-alias="dropDown_field_id_3"
                                                                                class="calc-drop-down-with-image-current calc-dd-toggle ccb-appearance-field"><span
                                                                                    class="calc-dd-with-option-label calc-dd-toggle">
                                                                                    CENT25
                                                                                </span> <i
                                                                                    class="ccb-icon-Path-3485 ccb-select-arrow calc-dd-toggle ccb-arrow-down"></i>
                                                                                <!----></span>
                                                                            <div class="calc-drop-down-list">
                                                                                <ul class="calc-drop-down-list-items">
                                                                                    <li value=""><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">Select
                                                                                                value</span></span></li>
                                                                                    <li value="2_0"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">ECN</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 2.00
                                                                                            </span></span></li>
                                                                                    <li value="9_1"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">STD</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 9.00
                                                                                            </span></span></li>
                                                                                    <li value="14_2"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">STD5</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 14.00
                                                                                            </span></span></li>
                                                                                    <li value="24_3"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">STD10</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 24.00
                                                                                            </span></span></li>
                                                                                    <li value="29_4"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">STD15</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 29.00
                                                                                            </span></span></li>
                                                                                    <li value="34_5"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">STD20</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 34.00
                                                                                            </span></span></li>
                                                                                    <li value="39_6"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">STD25</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 39.00
                                                                                            </span></span></li>
                                                                                    <li value="44_7"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">STD30</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 44.00
                                                                                            </span></span></li>
                                                                                    <li value="9_8"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">CENT</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 9.00
                                                                                            </span></span></li>
                                                                                    <li value="14_9"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">CENT5</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 14.00
                                                                                            </span></span></li>
                                                                                    <li value="24_10"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">CENT10</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 24.00
                                                                                            </span></span></li>
                                                                                    <li value="29_11"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">CENT15</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 29.00
                                                                                            </span></span></li>
                                                                                    <li value="34_12"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">CENT20</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 34.00
                                                                                            </span></span></li>
                                                                                    <li value="39_13"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">CENT25</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 39.00
                                                                                            </span></span></li>
                                                                                    <li value="44_14"><span
                                                                                            class="calc-list-wrapper"><span
                                                                                                class="calc-list-title">CENT30</span>
                                                                                            <span
                                                                                                class="calc-list-price">
                                                                                                Price: $ 44.00
                                                                                            </span></span></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="calc-item__description after"><span></span>
                                                                </div>
                                                            </div> <!---->
                                                            <div data-id="range_field_id_1" class="calc-item ccb-field"
                                                                format="" text-days="days"
                                                                converter="function () { [native code] }">
                                                                <div class="calc-range calc_range_field_id_1">
                                                                    <div class="calc-item__title ccb-range-field">
                                                                        <div class="ccb-range-label">
                                                                            Clients
                                                                            <!----> <!---->
                                                                        </div>
                                                                        <div class="ccb-range-value"> 14</div>
                                                                    </div>
                                                                    <div class="calc-item__description before">
                                                                        <span></span>
                                                                    </div>
                                                                    <div class="calc-range-slider range_range_field_id_1"
                                                                        style="--min: 0; --max: 200; --step: 1; --value: 14; --text-value: '14 '; --suffix: ''; --primary-color: #00B163;">
                                                                        <input type="range" min="0" max="200" step="1">
                                                                        <output
                                                                            class="cost-calc-range-output-free"></output>
                                                                        <div class="calc-range-slider__progress"></div>
                                                                    </div>
                                                                    <div class="calc-range-slider-min-max">
                                                                        <span>0</span> <span>200</span>
                                                                    </div>
                                                                    <div class="calc-item__description after">
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                            </div> <!----><!----> <!----><!----> <!---->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="" class="calc-subtotal calc-list loaded">
                                                    <div class="calc-subtotal-wrapper">
                                                        <div class="calc-list-inner">
                                                            <div class="calc-item-title calc-accordion">
                                                                <div class="ccb-calc-heading">
                                                                    Total Summary </div> <span
                                                                    class="calc-accordion-btn"><i
                                                                        class="ccb-icon-Path-3485"
                                                                        style="top: 1px; transform: rotate(180deg);"></i></span>
                                                            </div>
                                                            <div class="calc-item-title calc-accordion"
                                                                style="margin: 0px !important; display: none;">
                                                                <div class="ccb-calc-heading"
                                                                    style="text-transform: none !important; padding-bottom: 15px; word-break: break-word;">
                                                                    You will get a quote and invoice after submitting
                                                                    the form</div>
                                                            </div>
                                                            <div class="calc-subtotal-list show-unit">
                                                                <div class="calc-subtotal-list-accordion">
                                                                    <div class="calc-subtotal-list-header"><span
                                                                            class="calc-subtotal-list-header__name">Name</span>
                                                                        <span
                                                                            class="calc-subtotal-list-header__value">Total</span>
                                                                    </div>
                                                                    <div class="dropDown_field_id_3 sub-list-item"
                                                                        data-type="default-summary"
                                                                        style="display: flex;"><span
                                                                            class="sub-item-title">Acount Type</span>
                                                                        <span class="sub-item-space"></span> <!---->
                                                                        <span class="sub-item-value"> CENT25 </span>
                                                                        <!---->
                                                                    </div> <!----> <!---->
                                                                    <div class="range_field_id_1 sub-list-item"
                                                                        data-type="default-summary"
                                                                        style="display: flex;"><span
                                                                            class="sub-item-title">Clients</span> <span
                                                                            class="sub-item-space"></span> <span
                                                                            class="sub-item-value"> 14 </span> <!---->
                                                                        <!---->
                                                                    </div> <!----> <!---->
                                                                </div>
                                                            </div>
                                                            <div class="calc-subtotal-list totals unit-enable"
                                                                style="margin-top: 20px; padding-top: 10px;">
                                                                <div id="total_field_id_0" class="sub-list-item total">
                                                                    <span class="sub-item-title">Mark Up</span> <span
                                                                        class="sub-item-value"
                                                                        style="white-space: nowrap;">$ 25.00</span>
                                                                </div>
                                                                <div id="total_field_id_2" class="sub-list-item total">
                                                                    <span class="sub-item-title">Potential Income</span>
                                                                    <span class="sub-item-value"
                                                                        style="white-space: nowrap;">$ 546.00</span>
                                                                </div>
                                                            </div> <!----> <!---->
                                                            <div class="calc-subtotal-list calc-buttons"></div>
                                                        </div>
                                                        <div class="calc-list-inner calc-notice"
                                                            style="margin-top: 10px !important; display: none;">
                                                            <div class="calc-notice-wrap">
                                                                <div class="calc-notice-icon"><img alt="notice img">
                                                                </div>
                                                                <div class="calc-notice-content"><span
                                                                        class="calc-notice-title"></span> <!----></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div send-pdf-from="">
                                                        <div>
                                                            <div class="ccb-send-quote">
                                                                <div class="ccb-send-quote__overlay">
                                                                    <div class="ccb-send-quote__wrapper">
                                                                        <div class="ccb-send-quote__form">
                                                                            <div class="ccb-send-quote__header">
                                                                                <div class="ccb-send-quote__title">Email
                                                                                    Quote</div>
                                                                                <div class="ccb-send-quote__close"><i
                                                                                        class="ccb-icon-close"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div class="ccb-send-quote__body">
                                                                                <div class="ccb-send-quote__input">
                                                                                    <label for="username"
                                                                                        class="required">Name</label>
                                                                                    <input type="text"
                                                                                        placeholder="Enter name"
                                                                                        id="username">
                                                                                </div>
                                                                                <div class="ccb-send-quote__input">
                                                                                    <label for="useremail"
                                                                                        class="required">Email</label>
                                                                                    <input type="text"
                                                                                        placeholder="Enter Email"
                                                                                        id="useremail">
                                                                                </div>
                                                                                <div class="ccb-send-quote__textarea">
                                                                                    <label
                                                                                        for="usermessage">Message</label>
                                                                                    <textarea name="message"
                                                                                        id="usermessage" cols="30"
                                                                                        rows="10"
                                                                                        placeholder="Enter message"></textarea>
                                                                                </div>
                                                                                <div class="ccb-send-quote__file"><i
                                                                                        class="ccb-icon-pdf"></i> <span>
                                                                                        Rebate Calculator.pdf
                                                                                    </span></div>
                                                                                <div class="ccb-send-quote__submit">
                                                                                    <button>Send</button>
                                                                                </div> <!---->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div slot="pdf-content" class="">
                                                            <div class="ccb-invoice show-unit" style="display: none;">
                                                                <div class="ccb-invoice-container">
                                                                    <div class="ccb-invoice-header">
                                                                        <div class="ccb-invoice-logo"><span></span>
                                                                            <!---->
                                                                        </div>
                                                                        <div class="ccb-invoice-date"><span>08/19/2024
                                                                                21:33</span></div>
                                                                    </div>
                                                                    <div class="ccb-invoice-company"><span>

                                                                        </span></div>
                                                                    <div class="ccb-invoice-table">
                                                                        <div class="ccb-invoice-table__header">
                                                                            <span>Order: </span> <!---->
                                                                        </div>
                                                                        <div class="ccb-invoice-table__body">
                                                                            <div class="ccb-invoice-table__summary">
                                                                                <span
                                                                                    class="ccb-invoice-table__title">Total
                                                                                    Summary</span>
                                                                                <div class="calc-subtotal-list-header">
                                                                                    <span
                                                                                        class="calc-subtotal-list-header__name">Name</span>
                                                                                    <span
                                                                                        class="calc-subtotal-list-header__value">Total</span>
                                                                                </div>
                                                                                <ul>
                                                                                    <li><span
                                                                                            class="ccb-invoice-row"><span
                                                                                                class="ccb-invoice-row__label"
                                                                                                style="text-align: left;">Acount
                                                                                                Type</span> <!---->
                                                                                            <!----> <span
                                                                                                class="ccb-invoice-row__value">
                                                                                                CENT25 </span>
                                                                                            <!----></span> <!---->
                                                                                        <!----> <!---->
                                                                                    </li> <!---->
                                                                                    <li><span
                                                                                            class="ccb-invoice-row"><span
                                                                                                class="ccb-invoice-row__label"
                                                                                                style="text-align: left;">Clients</span>
                                                                                            <!----> <span
                                                                                                class="ccb-invoice-row__value">14</span>
                                                                                            <!----> <!----></span>
                                                                                        <!----> <!----> <!---->
                                                                                    </li>
                                                                                    <!---->
                                                                                </ul> <span
                                                                                    class="ccb-invoice-table__total"><span
                                                                                        class="ccb-left">Mark Up </span>
                                                                                    <span class="ccb-right">
                                                                                        $ 25.00
                                                                                    </span></span><span
                                                                                    class="ccb-invoice-table__total"><span
                                                                                        class="ccb-left">Potential
                                                                                        Income </span> <span
                                                                                        class="ccb-right">
                                                                                        $ 546.00
                                                                                    </span></span> <!---->
                                                                            </div> <!---->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>

import NoticeBan from '@/components/community/CommunityBan';
export default {
    components: {
        NoticeBan
    },
    data() {
        return {
            //   title: this.$t('newest.part_tip1')
        }
    },
    computed: {
        title() {
            return this.$t('newest.part_tip1');
        },
    },
    methods: {
        goTo(path) {
            this.$router.push({ path });

        }
    }
}
</script>
<style id="calc_appearance_17893">
.ccb-sticky-calc.ccb-calc-id-17893 .sticky-calculator-banner.top {
    border-bottom: 1px solid !important;
    border-color: #0019311A !important;
}

.ccb-sticky-calc.ccb-calc-id-17893 .sticky-calculator-banner.bottom {
    border-top: 1px solid !important;
    border-color: #0019311A !important;
}

.ccb-sticky-calc.ccb-calc-id-17893 .sticky-calculator-banner,
.ccb-sticky-calc.ccb-calc-id-17893 .sticky-calculator-btn {
    background-color: #ffffff !important;
}

.ccb-sticky-calc.ccb-calc-id-17893 .sticky-calculator-banner .sticky-bar-totals span,
.ccb-sticky-calc.ccb-calc-id-17893 .sticky-calculator-banner .sticky-bar-text__title,
.ccb-sticky-calc.ccb-calc-id-17893 .sticky-calculator-btn--text__title {
    color: #001931 !important;
}

.ccb-sticky-calc.ccb-calc-id-17893 .sticky-calculator-banner .sticky-bar-text__description,
.ccb-sticky-calc.ccb-calc-id-17893 .sticky-calculator-btn--text__description {
    color: #00193180 !important;
}

.ccb-sticky-calc.ccb-calc-id-17893 .sticky-calculator-banner .sticky-bar-icon i,
.ccb-sticky-calc.ccb-calc-id-17893 .ccb-icon-arrow i,
.ccb-sticky-calc.ccb-calc-id-17893 .sticky-calculator-btn--icon i {
    color: #00B163 !important;
}

.ccb-sticky-calc.ccb-calc-id-17893 .sticky-calculator-btn--icon img {
    filter: invert(46%) sepia(64%) saturate(2686%) hue-rotate(125deg) brightness(94%) contrast(101%);
}

#ccb-sticky-calc-modal-17893 .modal-content {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%), var(--white, #ffffff);
    border-radius: 8px !important;
}

#ccb-sticky-calc-modal-17893 .calculator-settings.ccb-front {
    margin: 10px auto;
}

#ccb-sticky-calc-modal-17893 .ccb-calc-edit {
    display: none !important;
}

.ccb-wrapper-17893 .calc-container .ccb-calc-edit {
    background-color: #ffffffFF !important;
    color: #001931 !important;
    border-color: #0019311A !important;
    border: 1px solid
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .calc-subtotal-list-accordion,
.ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list {
    row-gap: calc(20px / 2) !important;
    overflow: hidden;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-fields-container {
    row-gap: 20px !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-fields-container .calc-repeater-wrapper .calc-repeater-fields {
    row-gap: calc(20px - 8px) !important;
}


.ccb-wrapper-17893 .calc-thank-you-page-container.vertical,
.ccb-wrapper-17893 .calc-container.vertical {
    max-width: 970px !important;
}

.ccb-wrapper-17893 .calc-thank-you-page-container.horizontal,
.ccb-wrapper-17893 .calc-container.horizontal {
    max-width: 970px !important;
}

.ccb-wrapper-17893 .calc-thank-you-page-container.two_column,
.ccb-wrapper-17893 .calc-container.two_column {
    max-width: 1200px !important;
}

#ccb-desktop-preview .calc-thank-you-page-container.two_column,
#ccb-desktop-preview .calc-container.two_column {
    max-width: calc(1200px - 176px) !important;
}

.ccb-wrapper-17893 .calc-list-inner {
    padding: 20px 20px 20px 20px !important;
    margin: 0px 0px 0px 0px !important;
    border-radius: 8px !important;
    border: 1px solid #0019311A !important;

    -webkit-box-shadow: 0px 0px 0px #cccccc;
    -moz-box-shadow: 0px 0px 0px #cccccc !important;
    box-shadow: 0px 0px 0px #cccccc !important;

}

.ccb-wrapper-17893 .country-selector.has-value .country-selector__input,
.ccb-wrapper-17893 .input-tel__input,
.ccb-wrapper-17893 .ccb-datetime div .calc-date-picker-select,
.ccb-wrapper-17893 .calc-drop-down-with-image-list>ul,
.ccb-wrapper-17893 .calc-toggle-item .calc-toggle-wrapper label:after,
.ccb-wrapper-17893 .calc-checkbox-item label::before,
.ccb-wrapper-17893 .ccb-appearance-field,
.ccb-wrapper-17893 .ccb-location-popup-sidebar input,
.ccb-wrapper-17893 .ccb-location-popup__search input,
.ccb-wrapper-17893 .ccb-location-popup-sidebar span {
    background: #F7F7F7 !important;
    border-color: #0019311A !important;
    color: #001931 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.ccb-wrapper-17893 .input-tel.has-hint .input-tel__label,
.ccb-wrapper-17893 .input-tel.has-value .input-tel__label,
.ccb-wrapper-17893 .country-selector__label {
    color: #001931 !important;
}

.ccb-wrapper-17893 .input-tel.is-valid .input-tel__input {
    caret-color: #0019311A !important;
}

.ccb-wrapper-17893 .invalid-phone-number .input-tel.has-hint .input-tel__input,
.ccb-wrapper-17893 .required .input-tel .input-tel__input {
    border-color: #D94141 !important;
    box-shadow: none !important;
}

.ccb-wrapper-17893 .input-tel.is-valid .input-tel__input,
.ccb-wrapper-17893 .input-tel.is-valid .input-tel__input:hover {
    border-color: #00B163 !important;
    caret-color: unset !important;
    box-shadow: none !important;
}

.ccb-wrapper-17893 .country-selector.has-value .country-selector__input {
    box-shadow: none !important;
}

.ccb-wrapper-17893 .calc-item .calc-file-upload .calc-uploaded-files .ccb-uploaded-file-list-info>i:first-child {
    color: #00B163 !important;
}

.ccb-wrapper-17893 .ccb-time-picker-wrapper {
    background: #F7F7F7 !important;
    border: 1px solid #0019311A !important;
    border-radius: 4px !important;
}

.ccb-wrapper-17893 .ccb-time-picker-wrapper .vue__time-picker input.display-time {
    border-radius: 4px !important;
    background: #F7F7F7 !important;
    color: #001931 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.ccb-wrapper-17893 .ccb-time-picker-wrapper .vue__time-picker .controls .char {
    color: #001931B3 !important;
}

.ccb-wrapper-17893 .ccb-time-picker-wrapper .start .vue__time-picker input.display-time {
    border-radius: 4px 0 0 4px !important;
}

.ccb-wrapper-17893 .ccb-time-picker-wrapper .end .vue__time-picker input.display-time {
    border-radius: 0 4px 4px 0 !important;
}

.ccb-wrapper-17893 .ccb-time-picker-wrapper .separator {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.ccb-wrapper-17893 .ccb-time-picker-wrapper .vue__time-picker input {
    padding: 12px 12px 11px 12px !important;
    height: 38px !important;
    min-height: 38px !important;
    width: 100%;
}

.ccb-wrapper-17893 .ccb-time-picker .ccb-time-picker-wrapper .ccb-time-picker-range .separator {
    height: 38px !important;
    min-height: 38px !important;
    background: #F7F7F7bf !important;
    color: #001931 !important;
    border-color: #0019311A !important;
}

.ccb-wrapper-17893 .ccb-time-picker-wrapper .vue__time-picker input.display-time::placeholder,
.ccb-wrapper-17893 .ccb-time-picker-wrapper .vue__time-picker-dropdown ul li,
.vue__time-picker .dropdown ul li {
    color: #001931 !important;
}

.ccb-wrapper-17893 .ccb-time-picker-wrapper .vue__time-picker .dropdown ul li.active {
    color: #F7F7F7 !important;
}

.ccb-wrapper-17893 .vue__time-picker-dropdown,
.vue__time-picker .dropdown {
    background-color: #F7F7F7 !important;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action,
.ccb-wrapper-17893 .ccb-appearance-field:not(textarea) {
    min-height: 40px !important;
}

.ccb-wrapper-17893 .ccb-datetime div .calc-date-picker-select {
    min-height: 40px !important;
}

.ccb-wrapper-17893 .calc-repeater-wrapper {
    border-color: #0019311A !important;
}

.ccb-wrapper-17893 .ccb-datetime div .calc-date-picker-select,
.ccb-wrapper-17893 .ccb-appearance-field {
    padding: 12px 12px !important;
    border-radius: 4px !important;
    border: 1px solid #0019311A !important;
}

.ccb-wrapper-17893 .ccb-appearance-field.calc-drop-down {
    padding: 12px 22px 12px 12px !important;
}

.ccb-wrapper-17893 textarea {
    height: 12px;
}

.ccb-wrapper-17893 .calc-input-wrapper .input-number-counter {
    right: 5px !important;
    border-radius: 4px !important;
}


.ccb-wrapper-17893 .calc-checkbox .calc-checkbox-item label::after {
    border-left-color: #F7F7F7 !important;
    border-bottom-color: #F7F7F7 !important;
}

.ccb-wrapper-17893 .calc-radio-wrapper input[type=radio] {
    background: #F7F7F7 !important;
    border-color: #0019311A !important;
}

.calc-radio-wrapper input[type=radio]:checked:before {
    background: #F7F7F7 !important;
}

.ccb-wrapper-17893 .ccb-datetime div .calc-date-picker-select i,
.ccb-wrapper-17893 .ccb-time-picker-wrapper .ccb-icon-timepicker-light-clock,
.ccb-wrapper-17893 .calc-drop-down-with-image .ccb-select-arrow,
.ccb-wrapper-17893 .calc-item .calc-drop-down-box .ccb-select-arrow {
    color: #001931B3 !important;
}

.ccb-wrapper-17893 .calc-item .ccb-time-picker-wrapper input:focus .ccb-wrapper-17893 .calc-item .ccb-time-picker-wrapper {
    border-color: #00B163 !important;
}

.ccb-wrapper-17893 .calc-item textarea:focus,
.ccb-wrapper-17893 .calc-item .calc-drop-down-with-image-current.calc-dd-selected,
.ccb-wrapper-17893 .calc-item .ccb-datetime div .calc-date-picker-select.open,
.ccb-wrapper-17893 .calc-item .calc-input-wrapper .calc-input:focus,
.ccb-wrapper-17893 .calc-item .calc-drop-down-box .calc-drop-down:focus {
    border-color: #00B163 !important;
}

.ccb-wrapper-17893 .calc-drop-down-with-image-list-items li span.calc-list-wrapper .calc-list-price,
.ccb-wrapper-17893 .calc-drop-down-with-image-list-items li span.calc-list-wrapper .calc-list-title {
    color: #001931 !important;
    font-weight: 500 !important;
}

.ccb-wrapper-17893 .calc-item .calc-checkbox-item input[type=checkbox]:checked~label:before {
    border-color: #00B163 !important;
    background-color: #00B163 !important;
}

.ccb-wrapper-17893 .calc-toggle-wrapper label {
    background: #00193133 !important;
}

.ccb-wrapper-17893 .calc-toggle-wrapper input:checked+label,
.ccb-wrapper-17893 .calc-item .calc-radio-wrapper input[type=radio]:checked {
    background: #00B163 !important;
}

.ccb-wrapper-17893 .calc-item .calc-radio-wrapper input[type=radio]:checked {
    border-color: #00B163 !important;
}

.ccb-wrapper-17893 .calc-range-slider__progress {
    background: #00193140 !important;
}

.ccb-wrapper-17893 .calc-range-slider>input:nth-of-type(2)+output,
.ccb-wrapper-17893 .calc-range-slider>input:nth-of-type(1)+output {
    color: #F7F7F7 !important;
}

.ccb-wrapper-17893 .calc-checkbox.calc-checkbox-image .calc-checkbox-info .calc-checkbox-label,
.ccb-wrapper-17893 .calc-radio-wrapper.calc-radio-image .calc-radio-info .calc-radio-label {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #001931 !important;
    word-break: break-word;
}

.ccb-wrapper-17893 .calc-checkbox.calc-checkbox-image .calc-checkbox-info .calc-checkbox-price,
.ccb-wrapper-17893 .calc-radio-wrapper.calc-radio-image .calc-radio-info .calc-radio-price {
    color: #001931 !important;
    font-size: calc(14px - 2px) !important;
    color: #00193180 !important;
}

.ccb-wrapper-17893 .calc-applied-title {
    color: #001931 !important;
}

.ccb-wrapper-17893 .ccb-promocode {
    background: #0019311A !important;
    color: #001931 !important;
}

.ccb-sticky-calc.ccb-calc-id-17893 .sticky-bar-action button,
.ccb-wrapper-17893 .ccb-discount-off,
.ccb-wrapper-17893 .calc-promocode-container button {
    color: #F7F7F7 !important;
    background-color: #00B163 !important;
}

.ccb-sticky-calc.ccb-calc-id-17893 .sticky-bar-action button,
.ccb-wrapper-17893 .calc-promocode-container button:hover {
    background: #00a35b !important;
}


.ccb-wrapper-17893 .calc-range-slider::before,
.ccb-wrapper-17893 .calc-range-slider::after {
    opacity: 0 !important;
}

.ccb-wrapper-17893 .ccb-promocode-hint,
.ccb-wrapper-17893 .calc-have-promocode {
    color: #00B163 !important;
    ;
}

.ccb-wrapper-17893 .calc-range-slider-min-max span {
    color: #00193180 !important;
}

.ccb-wrapper-17893 .e-control-wrapper.e-slider-container.e-material-slider .e-slider .e-handle.e-handle-first,
.ccb-wrapper-17893 .e-slider-tooltip.e-tooltip-wrap.e-popup,
.ccb-wrapper-17893 .e-control-wrapper.e-slider-container .e-slider .e-handle,
.ccb-wrapper-17893 .e-control-wrapper.e-slider-container .e-slider .e-range {
    background: #00B163 !important;
}

.ccb-wrapper-17893 .e-slider-tooltip.e-tooltip-wrap.e-popup:after {
    border-color: #00B163 transparent transparent transparent !important;
}

.ccb-wrapper-17893 .calc-checkbox.calc-checkbox-image .calc-checkbox-image-wrapper,
.ccb-wrapper-17893 .calc-radio-wrapper.calc-radio-image .calc-radio-image-wrapper {
    border-color: #0019311A !important;
}

.ccb-wrapper-17893 .calc-checkbox.calc-checkbox-image .calc-checkbox-image-wrapper.calc-checkbox-image-selected,
.ccb-wrapper-17893 .calc-radio-wrapper.calc-radio-image .calc-radio-image-wrapper.calc-radio-image-selected {
    border-color: #00B163 !important;
}

.e-slider-tooltip.e-tooltip-wrap.e-popup.calc_id_17893:after {
    border-color: #00B163 transparent transparent transparent !important;
}

.e-slider-tooltip.e-tooltip-wrap.e-popup.calc_id_17893 {
    background: #00B163 !important;
}

.ccb-wrapper-17893 .calc-list .calc-item-title {
    margin-bottom: 20px;
}

.ccb-wrapper-17893 .calc-list .calc-item-title .ccb-calc-heading {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #001931 !important;
    text-transform: capitalize !important;
    word-break: break-all;
}

.ccb-wrapper-17893 .calc-item__title,
.ccb-location-popup-sidebar {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #001931 !important;
    margin-bottom: 4px;
    word-break: break-word;
}

.ccb-wrapper-17893 .calc-geolocation__info {
    color: #001931 !important;
}

.ccb-wrapper-17893 .ccb-terms-text a {
    color: #001931 !important;
    text-decoration: none
}

.ccb-wrapper-17893 .calc-item__title.ccb-repeater-field {
    font-size: calc(14px + 2px) !important;
    margin-top: 4px;
}

.ccb-thank-you-17893 .calc-notice.success,
.ccb-wrapper-17893 .calc-notice.success {
    background: #00B16333 !important;
}

.ccb-thank-you-17893 .calc-notice.success .calc-notice-icon-wrapper,
.ccb-wrapper-17893 .calc-notice.success .calc-notice-icon-wrapper {
    background-color: #00B1631A !important;
}

.ccb-thank-you-17893 .calc-notice.success .calc-notice-icon-content,
.ccb-wrapper-17893 .calc-notice.success .calc-notice-icon-content {
    background-color: #00B163 !important;
}

.ccb-thank-you-17893 .calc-notice.success .calc-notice-icon-content,
.ccb-wrapper-17893 .calc-notice.success .calc-notice-icon-content {
    background-color: #00B163 !important;
}

.ccb-thank-you-17893 .calc-notice.success .calc-notice-icon-content i,
.ccb-wrapper-17893 .calc-notice.success .calc-notice-icon-content i {
    color: #fff;
}

.ccb-thank-you-17893 .calc-notice.success .calc-notice-title,
.ccb-wrapper-17893 .calc-notice.success .calc-notice-title {
    color: #00B163 !important;
}

.ccb-wrapper-17893 .calc-toggle-container .calc-toggle-item .calc-toggle-label-wrap .calc-toggle-label,
.ccb-wrapper-17893 .calc-item .calc-radio-wrapper label .calc-radio-label,
.ccb-wrapper-17893 .calc-item .calc-checkbox-item label .calc-checkbox-title {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #001931 !important;
    word-break: break-word;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action,
.ccb-wrapper-17893 .ccb-location-popup__footer button {
    background: #0019310D !important;
    color: #001931 !important;
    font-weight: 500 !important;
    font-size: calc(14px - 2px) !important;
    border-radius: 4px !important;
    border: 1px solid #0019310D !important;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action.is-bold {
    font-weight: 700 !important;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action:hover {
    background: #00132626 !important;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action.success,
.ccb-wrapper-17893 .ccb-location-popup__footer .success {
    background: #00B163 !important;
    color: #F7F7F7 !important;
}

.ccb-wrapper-17893 .calc-terms-link {
    color: #00B163 !important;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action.success:hover {
    background: #00a35b !important;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action.success-with-border,
.ccb-wrapper-17893 .calc-buttons .calc-btn-action.default-with-border {
    height: 40px !important;
    min-height: 40px !important;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action.success-with-border {
    background: #F7F7F7 !important;
    color: #00B163 !important;
    border: 2px solid #00B163 !important;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action.success-with-border:hover {
    background: #00B163 !important;
    color: #F7F7F7 !important;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action.success-with-border i::before {
    transition: color 200ms linear;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action.success-with-border:hover i::before {
    color: #F7F7F7 !important;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action.default-with-border {
    background: #F7F7F7 !important;
    color: #00193199 !important;
    border: 2px solid #0019314D !important;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action.default-with-border:hover {
    color: #00193180 !important;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action.default-with-border.danger {
    color: #D94141 !important;
    border: 1px solid #D94141 !important;
}

.ccb-wrapper-17893 .calc-buttons .calc-btn-action.default-with-border.danger:hover {
    color: #D94141 !important;
    background: #D941411A !important;
}

.ccb-wrapper-17893 .calc-item .calc-input-wrapper .input-number-counter {
    background: #0019311D !important;
    color: #001931 !important;
    transition: 200ms ease-in-out;
}

.ccb-wrapper-17893 .calc-stripe-wrapper {
    width: 100%;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-accordion-btn {
    background: #0019311a !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-accordion-btn>i {
    color: #001931 !important;
}

.ccb-wrapper-17893 .calc-item .calc-input-wrapper .input-number-counter:hover {
    background: #0019311A !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .sub-list-item {
    color: #001931 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .sub-item-unit,
.ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .sub-list-item .sub-inner {
    color: #001931B3 !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .calc-subtotal-list-header {
    color: #001931 !important;
    background-color: #0019311A !important;
    text-transform: capitalize !important;
    border-radius: 4px !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .calc-subtotal-list-header span {
    font-size: 14px !important;
    font-weight: 600 !important;
}

.ccb-wrapper-17893 .calc-container .show-unit .sub-inner .sub-item-value {
    padding-right: 5px;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .sub-list-item.total {
    color: #001931 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .sub-list-item.total .ccb-discount {
    color: #001931 !important;
    font-size: calc(18px - 4px) !important;
    font-weight: 500 !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .sub-list-item.total .ccb-discounts-inner-label {
    color: #001931 !important;
    font-size: calc(18px - 4px) !important;
    font-weight: 500 !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .sub-list-item.total .ccb-discount-title {
    color: #001931 !important;
    font-size: calc(18px - 4px) !important;
    font-weight: 600 !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list.totals {
    border-bottom: 1px solid #0019311A !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .sub-list-item.total .sub-item-title {
    text-transform: capitalize !important;
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select {
    background: #F7F7F7 !important;
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .day-list .week .day {
    background: #0019310D !important;
    color: #001931 !important;
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .month-slide-control>div {
    background: #0019310D !important;
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .month-slide-control div i,
.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .day-list .week-titles .title {
    color: #001931 !important;
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .slider-title>div {
    color: #001931 !important;

    &:hover {
        color: #00B163 !important;
    }
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .decade-title,
.year-title,
.month-title {
    color: #001931 !important;

    &:hover {
        background: #0019310D;
        color: #00B163 !important;
    }
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .decade,
.year,
.month {
    .selected {
        color: white !important;
        background: #00B163;

        &:hover {
            background: #00B163 !important;
        }
    }
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .current-year,
.current-month,
.current-decade {
    color: #00B163;
    border: 2px solid #00B163;
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .current-year.selected,
.current-month.selected,
.current-decade.selected {
    color: white;
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .day-list .week .day:hover {
    background: #00B16326 !important;
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .day-list .week .day.today {
    border: 2px solid #00B163 !important;
    color: #00B163 !important;
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .day-list .week .day:hover {
    border: 2px solid #00B163 !important;
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .day-list .week .day.selected {
    background: #00B163 !important;
    color: white !important;
    border: 2px solid #00B163 !important;
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .day-list .week .day.selected.not-allowed {
    background: #0019310D !important;
    color: #001931 !important;
    border-color: transparent !important;
}

.ccb-wrapper-17893 .ccb-datetime div.date .calendar-select .month-slide-control div.slider-title {
    background: #0019310D !important;
    color: #001931 !important;
}

.ccb-wrapper-17893 .calc-drop-down-with-image-list-items li {
    background: #F7F7F7 !important;
    border-bottom: 1px solid #0019311A !important;
}

.ccb-wrapper-17893 .calc-drop-down-with-image-list-items li:hover {
    background: #0019311a !important;
}

.ccb-wrapper-17893 .calc-drop-down-with-image-list-items li:last-child {
    border-bottom: none !important;
}

.ccb-wrapper-17893 .calc-item .calc-file-upload .calc-uploaded-files .file-name {
    background: #0019311A !important;
    color: #001931 !important;
}

.ccb-wrapper-17893 .calc-item__description span {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #00193180 !important;
    word-break: break-word;
}

.ccb-wrapper-17893 .calc-payments .calc-radio-wrapper label>div.calc-payment-body .ccb-payment-description,
.ccb-wrapper-17893 .calc-item__description span {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #00193180 !important;
}

.ccb-wrapper-17893 .calc-item__description.after {
    display: block !important;
}

.ccb-wrapper-17893 .ccb-field.required .calc-required-field .ccb-field-required-tooltip,
.ccb-wrapper-17893 .ccb-error-tip.front {
    color: #ffffff !important;
    background: #D94141 !important;
}

.ccb-wrapper-17893 .ccb-field.required .calc-required-field .ccb-field-required-tooltip-text::after {
    border: 7px solid #D94141 !important;
    border-color: #D94141 transparent transparent transparent !important;
}

.ccb-wrapper-17893 .ccb-error-tip.front::after {
    border-top: 10px solid #D94141 !important;
}

.ccb-wrapper-17893 .ccb-loader {
    border: 6px solid #F7F7F726 !important;
    border-top: 6px solid #00B163 !important;
}

.ccb-wrapper-17893 .ccb-loader-1 div {
    background: #00B163 !important;
}

.ccb-wrapper-17893 .ccb-loader-3 div:after,
.ccb-wrapper-17893 .ccb-loader-2 div:after {
    background: #00B163 !important;
}

.ccb-wrapper-17893 .ccb-loader-4 circle,
.ccb-wrapper-17893 .ccb-loader-4 path {
    fill: #00B163 !important;
}

.ccb-wrapper-17893 .ccb-checkbox-hint {
    color: #001931 !important
}

.ccb-wrapper-17893 .calc-item .calc-file-upload .info-tip-block .info-icon {
    color: #001931 !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-list-inner .calc-item-title-description {
    color: #00193180 !important;
    font-size: calc(14px - 2px) !important;
}


.ccb-wrapper-17893 .ccb-field.required .ccb-datetime div .calc-date-picker-select,
.ccb-wrapper-17893 .ccb-field.required .calc-drop-down-with-image-list>ul,
.ccb-wrapper-17893 .ccb-field.required .calc-toggle-item .calc-toggle-wrapper label:after,
.ccb-wrapper-17893 .ccb-field.required .calc-checkbox-item label::before,
.ccb-wrapper-17893 .ccb-field.required .ccb-appearance-field,
.ccb-wrapper-17893 .ccb-field.required .ccb-appearance-field:hover,
.ccb-wrapper-17893 .ccb-field.required .ccb-appearance-field:focus,
.ccb-wrapper-17893 .ccb-field.required .ccb-appearance-field:active,
.ccb-wrapper-17893 .ccb-field.required .calc-drop-down-with-image-current,
.ccb-wrapper-17893 .ccb-field.required input[type=text],
.ccb-wrapper-17893 .ccb-field.required input[type=radio],
.ccb-wrapper-17893 .ccb-field.required input[type=number],
.ccb-wrapper-17893 .ccb-field.required textarea,
.ccb-wrapper-17893 .ccb-field.required select {
    border-color: #D94141 !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-item.required .ccb-required-mark,
.ccb-wrapper-17893 .calc-container .calc-list .calc-item.required .calc-item__title {
    color: #D94141 !important;
}

.ccb-wrapper-17893 .calc-item .calc-file-upload .calc-uploaded-files .ccb-uploaded-file-list-info .ccb-select-anchor,
.ccb-wrapper-17893 .calc-item .calc-file-upload .calc-uploaded-files .ccb-uploaded-file-list-info span {
    color: #001931 !important;
}

.ccb-wrapper-17893 .calc-container .ccb-cf-wrap .wpcf7-submit {
    background-color: #00B163 !important;
    color: #F7F7F7 !important;
}

.ccb-wrapper-17893 .calc-list-inner .calc-subtotal-list .calc-woo-product {
    border-color: #00B163;
    background-color: #00B1630d;
    color: #001931;
}

.ccb-wrapper-17893 .calc-list-inner .calc-subtotal-list .calc-woo-product .calc-woo-product__btn a {
    border-radius: 4px !important;
    background-color: #00B163;
    color: #F7F7F7 !important;
    font-size: calc(14px - 2px) !important;
    font-weight: 500 !important;
    min-height: 40px !important;
}

.ccb-wrapper-17893 .calc-checkbox.boxed-with-checkbox-and-description .calc-checkbox-item label,
.ccb-wrapper-17893 .calc-checkbox.boxed-with-description .calc-checkbox-item label,
.ccb-wrapper-17893 .calc-checkbox.boxed-with-checkbox .calc-checkbox-item label,
.ccb-wrapper-17893 .calc-checkbox.boxed .calc-checkbox-item label {
    background: #F7F7F7 !important;
    border: 1px solid #00193133 !important;
    border-radius: 4px !important;
}

.ccb-wrapper-17893 .calc-checkbox.boxed-with-checkbox-and-description .calc-checkbox-item input:checked+label,
.ccb-wrapper-17893 .calc-checkbox.boxed-with-description .calc-checkbox-item input:checked+label,
.ccb-wrapper-17893 .calc-checkbox.boxed-with-checkbox .calc-checkbox-item input:checked+label,
.ccb-wrapper-17893 .calc-checkbox.boxed .calc-checkbox-item input:checked+label {
    background: #00B163 !important;
    border: 1px solid #00B163 !important;
}

.ccb-wrapper-17893 .calc-checkbox.boxed-with-checkbox-and-description .calc-checkbox-item input:checked+label .calc-checkbox-title,
.ccb-wrapper-17893 .calc-checkbox.boxed-with-description .calc-checkbox-item input:checked+label .calc-checkbox-title,
.ccb-wrapper-17893 .calc-checkbox.boxed-with-checkbox .calc-checkbox-item input:checked+label .calc-checkbox-title,
.ccb-wrapper-17893 .calc-checkbox.boxed .calc-checkbox-item input:checked+label .calc-checkbox-title {
    color: #F7F7F7;
}

.ccb-wrapper-17893 .calc-checkbox.boxed-with-checkbox-and-description .calc-checkbox-item input:checked~label:before,
.ccb-wrapper-17893 .calc-checkbox.boxed-with-description .calc-checkbox-item input:checked~label:before,
.ccb-wrapper-17893 .calc-checkbox.boxed-with-checkbox .calc-checkbox-item input:checked~label:before,
.ccb-wrapper-17893 .calc-checkbox.boxed .calc-checkbox-item input:checked~label:before {
    border: 1px solid #00B163 !important;
    background-color: #00B163 !important;
}

.ccb-wrapper-17893 .calc-checkbox.boxed-with-checkbox-and-description .calc-checkbox-item input:checked+label,
.ccb-wrapper-17893 .calc-checkbox.boxed-with-description .calc-checkbox-item input:checked+label,
.ccb-wrapper-17893 .calc-checkbox.boxed-with-checkbox .calc-checkbox-item input:checked+label {
    background: #00B1631A !important;
    border: 1px solid #00B163;
}

.ccb-wrapper-17893 .calc-checkbox.boxed-with-checkbox-and-description .calc-checkbox-item input:checked+label .calc-checkbox-title,
.ccb-wrapper-17893 .calc-checkbox.boxed-with-description .calc-checkbox-item input:checked+label .calc-checkbox-title,
.ccb-wrapper-17893 .calc-checkbox.boxed-with-checkbox .calc-checkbox-item input:checked+label .calc-checkbox-title {
    color: #001931 !important;
}

.ccb-wrapper-17893 .calc-checkbox.boxed-with-checkbox-and-description .calc-checkbox-item .calc-checkbox-description,
.ccb-wrapper-17893 .calc-checkbox.boxed-with-description .calc-checkbox-item .calc-checkbox-description,
.ccb-wrapper-17893 .calc-checkbox.boxed-with-checkbox .calc-checkbox-item .calc-checkbox-description {
    color: #001931B3 !important;
    font-size: calc(14px - 2px) !important;
}

.ccb-wrapper-17893 .calc-radio-wrapper.boxed-with-radio .calc-radio-item label,
.ccb-wrapper-17893 .calc-radio-wrapper.boxed .calc-radio-item label {
    background: #F7F7F7 !important;
    border: 1px solid #00193133 !important;
    border-radius: 4px !important;
}

.ccb-wrapper-17893 .calc-radio-wrapper.boxed-with-radio .calc-radio-item input:checked+label,
.ccb-wrapper-17893 .calc-radio-wrapper.boxed .calc-radio-item input:checked+label {
    background: #00B163 !important;
    border: 1px solid #00B163 !important;
}

.ccb-wrapper-17893 .calc-radio-wrapper.boxed .calc-radio-item input:checked+label {
    background: #00B163 !important;
    border: 1px solid #00B163 !important;
}

.ccb-wrapper-17893 .calc-radio-wrapper.boxed-with-radio .calc-radio-item input:checked+label {
    background: #00B1631A !important;
    border: 1px solid #00B163 !important;
}

.ccb-wrapper-17893 .calc-radio-wrapper.boxed-with-radio .calc-radio-item input:checked+label .calc-radio-label {
    color: #001931 !important;
}

.ccb-wrapper-17893 .calc-toggle-container.boxed-with-toggle-and-description .calc-toggle-item {
    background: #F7F7F7 !important;
    border: 1px solid #00193133 !important;
    border-radius: 4px !important;
}


.calc-toggle-container.boxed-with-toggle-and-description .calc-toggle-item.calc-is-checked {
    border: 1px solid #00B163 !important;
    background: #00B1631A !important;
}

.ccb-wrapper-17893 .calc-toggle-container.boxed-with-toggle-and-description .calc-toggle-item .calc-toggle-label-wrap .calc-toggle-description {
    color: #001931B3 !important;
    font-size: calc(14px - 2px) !important;
}

.ccb-wrapper-17893 .calc-checkbox.calc-checkbox-image.with-icon .calc-checkbox-image-wrapper img,
.ccb-wrapper-17893 .calc-radio-wrapper.calc-radio-image.with-icon .calc-radio-image-wrapper img {}

.ccb-wrapper-17893 .calc-radio-wrapper.calc-radio-image.with-icon .calc-radio-image-wrapper,
.ccb-wrapper-17893 .calc-checkbox.calc-checkbox-image.with-icon .calc-checkbox-image-wrapper {
    background: #F7F7F7 !important;
    border: 1px solid #00193133 !important;
    border-radius: 4px !important;
}

.ccb-wrapper-17893 .calc-radio-wrapper.calc-radio-image.with-icon .calc-radio-image-wrapper.calc-radio-image-selected,
.ccb-wrapper-17893 .calc-checkbox.calc-checkbox-image.with-icon .calc-checkbox-image-wrapper.calc-checkbox-image-selected {
    border: 1px solid #00B163 !important;
    background: #00B1631A !important;
}

.ccb-wrapper-17893 .calc-repeater-subtotal-header i {
    font-size: calc(14px - 4px) !important;
    color: #00193180 !important;
}

.ccb-wrapper-17893 .calc-repeater-subtotal-header span {
    color: #001931 !important;
    font-size: calc(14px - 2px) !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .calc-repeater-subtotal .sub-list-item {
    font-size: calc(14px - 2px) !important;
}

.ccb-thank-you-17893 .thank-you-page {
    border-color: #0019311A !important;
    background-color: #ffffffFF !important;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page__title-box-title {
    color: #001931 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page-close {
    background: #0019310D !important;
    color: #00193166 !important;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page-close:hover {
    background: #00132626 !important;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page__order span span,
.ccb-thank-you-17893 .thank-you-page .thank-you-page__title-box-desc {
    color: #001931 !important;
    font-size: calc(14px - 2px) !important;
    font-weight: 400 !important;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page__title-box-desc {
    word-break: break-word;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div a,
.ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div button {
    background: #0019310D !important;
    color: #001931 !important;
    border-radius: 4px !important;
    border: 1px solid #0019310D !important;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div a span,
.ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div button span {
    font-weight: 600 !important;
    font-size: calc(18px - 2px) !important;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div a:hover,
.ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div button:hover {
    background: #00132626 !important;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div a.calc-success,
.ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div button.calc-success {
    background: #00B163 !important;
    color: #F7F7F7 !important;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div a.calc-success:hover,
.ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div button.calc-success:hover {
    background: #00a35b !important;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div a.calc-secondary,
.ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div button.calc-secondary {
    background: #F7F7F7 !important;
    color: #00B163 !important;
    border-color: #00B163 !important;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div a.calc-secondary:hover,
.ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div button.calc-secondary:hover {
    background: #00a35b !important;
    border-color: transparent !important;
    color: #F7F7F7 !important;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page__icon-box .icon-wrapper {
    background-color: #00B1631A !important;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page__icon-box .icon-wrapper .icon-content {
    background-color: #00B163 !important;
}

.ccb-thank-you-17893 .thank-you-page .thank-you-page__icon-box .icon-wrapper .icon-content i {
    color: #F7F7F7 !important;
}

.ccb-wrapper-17893 .calc-container .calc-list .ccb-pro-feature-header {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #001931 !important;
}

.ccb-wrapper-17893 .calc-payments .calc-radio-wrapper label>div.calc-payment-header .calc-payment-header--label .calc-radio-label {
    color: #001931 !important;
    font-size: calc(18px - 2px) !important;
    font-weight: 600 !important;
}

.ccb-wrapper-17893 .calc-payments .calc-radio-wrapper label {
    background: #0019310D !important;
}

.ccb-wrapper-17893 .calc-list-inner,
.ccb-wrapper-17893 .ccb-location-popup__footer {
    background-color: #ffffffFF !important;
}

.ccb-wrapper-17893 .ccb-location-popup-sidebar {
    background-color: #ffffffFF !important;
    opacity: 0.8;
}

#ccb-mobile-preview .ccb-wrapper-17893 .calc-list-inner {
    padding: 10px 10px 10px 10px !important;
    margin: 0px 0px 0px 0px !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 .calc-buttons .calc-btn-action,
#ccb-mobile-preview .ccb-wrapper-17893 .ccb-appearance-field:not(textarea) {
    min-height: 40px !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 .ccb-datetime div .calc-date-picker-select {
    min-height: 40px !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 .calc-buttons .calc-btn-action,
#ccb-mobile-preview .ccb-wrapper-17893 .ccb-datetime div .calc-date-picker-select,
#ccb-mobile-preview .ccb-wrapper-17893 .ccb-appearance-field {
    padding: 12px 12px !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 .calc-buttons .calc-file-upload-actions .calc-btn-action {
    padding: 12px 0 !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 textarea {
    height: 12px;
}

#ccb-mobile-preview .ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .calc-subtotal-list-accordion,
#ccb-mobile-preview .ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list {
    row-gap: calc(20px / 2) !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 .calc-container .calc-list .calc-fields-container {
    row-gap: 20px !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 .ccb-datetime div .calc-date-picker-select,
#ccb-mobile-preview .ccb-wrapper-17893 calc-drop-down-with-image-list>ul,
#ccb-mobile-preview .ccb-wrapper-17893 .calc-toggle-item .calc-toggle-wrapper label:after,
#ccb-mobile-preview .ccb-wrapper-17893 .calc-checkbox-item label::before,
#ccb-mobile-preview .ccb-wrapper-17893 .ccb-appearance-field {
    font-size: 12px !important;
    font-weight: 500 !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 .calc-item__description span {
    font-size: 12px !important;
    font-weight: 500 !important;
}


#ccb-mobile-preview .ccb-wrapper-17893 .calc-toggle-container .calc-toggle-item .calc-toggle-label-wrap .calc-toggle-label,
#ccb-mobile-preview .ccb-wrapper-17893 .calc-item .calc-radio-wrapper label .calc-radio-label,
#ccb-mobile-preview .ccb-wrapper-17893 .calc-item .calc-checkbox-item label .calc-checkbox-title {
    font-size: 12px !important;
    font-weight: 500 !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 .calc-buttons .calc-btn-action {
    font-weight: 500 !important;
    font-size: calc(12px - 2px) !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 .calc-list .calc-item-title .ccb-calc-heading {
    font-size: 16px !important;
    font-weight: 600 !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .calc-subtotal-list-header span {
    font-size: 12px !important;
    font-weight: 600 !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 .calc-item__title {
    font-size: 12px !important;
    font-weight: 600 !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .sub-list-item {
    font-size: 12px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .sub-list-item.total {
    color: #001931 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

#ccb-mobile-preview .ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .sub-list-item.total .ccb-discount {
    color: #001931 !important;
    font-size: calc(16px - 2px) !important;
    font-weight: 500 !important;
}

.ccb-wrapper-17893 .ccb-send-quote__wrapper {
    background-color: #ffffff !important;
}

.ccb-wrapper-17893 .ccb-send-quote__wrapper .ccb-send-quote__title,
.ccb-wrapper-17893 .ccb-send-quote__wrapper label {
    color: #001931 !important;
}

.ccb-wrapper-17893 .ccb-send-quote__wrapper .ccb-send-quote__submit button,
.ccb-wrapper-17893 .ccb-send-quote__success .ccb-send-quote__success-btn {
    background: #00B163 !important;
    color: #F7F7F7 !important;
    border-radius: 4px !important;
}

.ccb-wrapper-17893 .ccb-send-quote__success .ccb-send-quote__success-icon {
    color: #00B163 !important;
    background: #00B1631A !important;
}

.ccb-wrapper-17893 .ccb-send-quote__success .ccb-send-quote__success-text {
    color: #001931 !important;
}

.ccb-wrapper-17893 .ccb-send-quote__wrapper .ccb-send-quote__file {
    border-radius: 4px !important;
}

.ccb-wrapper-17893 .ccb-send-quote__wrapper .ccb-send-quote__input input,
.ccb-wrapper-17893 .ccb-send-quote__wrapper .ccb-send-quote__textarea textarea {
    border-radius: 4px !important;
    border: 1px solid #0019311A !important;
    background: #F7F7F7 !important;
    color: #001931 !important;
}

#ccb-mobile-preview .ccb-thank-you-17893 .thank-you-page .thank-you-page__title-box-title {
    font-size: 16px !important;
    font-weight: 600 !important;
}

#ccb-mobile-preview .ccb-thank-you-17893 .thank-you-page .thank-you-page__order span span,
#ccb-mobile-preview .ccb-thank-you-17893 .thank-you-page .thank-you-page__title-box-desc {
    font-size: calc(12px - 2px) !important;
    font-weight: 400 !important;
}

#ccb-mobile-preview .ccb-thank-you-17893 .thank-you-page .thank-you-page__title-box-desc {
    word-break: break-word;
}

#ccb-mobile-preview .ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div a span,
#ccb-mobile-preview .ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div button span {
    font-weight: 600 !important;
    font-size: calc(16px - 2px) !important;
}

@media only screen and (max-width: 480px) {

    .ccb-wrapper-17893 .calc-list-inner {
        padding: 10px 10px 10px 10px !important;
        margin: 0px 0px 0px 0px !important;
    }

    .ccb-wrapper-17893 .calc-buttons .calc-btn-action,
    .ccb-wrapper-17893 .ccb-appearance-field:not(textarea) {
        min-height: 40px !important;
    }

    .ccb-wrapper-17893 .ccb-datetime div .calc-date-picker-select {
        min-height: 40px !important;
    }

    .ccb-wrapper-17893 .calc-buttons .calc-btn-action,
    .ccb-wrapper-17893 .ccb-datetime div .calc-date-picker-select,
    .ccb-wrapper-17893 .ccb-appearance-field {
        padding: 12px 12px !important;
    }

    .ccb-wrapper-17893 .calc-buttons .calc-file-upload-actions .calc-btn-action {
        padding: 12px 0 !important;
    }

    .ccb-wrapper-17893 textarea {
        height: 12px;
    }

    .ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .calc-subtotal-list-accordion,
    .ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list {
        row-gap: calc(20px / 2) !important;
    }

    .ccb-wrapper-17893 .calc-container .calc-list .calc-fields-container {
        row-gap: 20px !important;
    }

    .ccb-wrapper-17893 .ccb-datetime div .calc-date-picker-select,
    .ccb-wrapper-17893 calc-drop-down-with-image-list>ul,
    .ccb-wrapper-17893 .calc-toggle-item .calc-toggle-wrapper label:after,
    .ccb-wrapper-17893 .calc-checkbox-item label::before,
    .ccb-wrapper-17893 .ccb-appearance-field {
        font-size: 12px !important;
        font-weight: 500 !important;
    }

    .ccb-wrapper-17893 .calc-item__description span {
        font-size: 12px !important;
        font-weight: 500 !important;
    }


    .ccb-wrapper-17893 .calc-toggle-container .calc-toggle-item .calc-toggle-label-wrap .calc-toggle-label,
    .ccb-wrapper-17893 .calc-item .calc-radio-wrapper label .calc-radio-label,
    .ccb-wrapper-17893 .calc-item .calc-checkbox-item label .calc-checkbox-title {
        font-size: 12px !important;
        font-weight: 500 !important;
    }

    .ccb-wrapper-17893 .calc-buttons .calc-btn-action {
        font-weight: 500 !important;
        font-size: calc(12px - 2px) !important;
    }

    .ccb-wrapper-17893 .calc-list .calc-item-title .ccb-calc-heading {
        font-size: 16px !important;
        font-weight: 600 !important;
    }

    .ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .calc-subtotal-list-header span {
        font-size: 12px !important;
        font-weight: 600 !important;
    }

    .ccb-wrapper-17893 .calc-item__title {
        font-size: 12px !important;
        font-weight: 600 !important;
    }

    .ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .sub-list-item {
        font-size: 12px !important;
        font-weight: 400 !important;
        text-transform: capitalize !important;
    }

    .ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .sub-list-item.total {
        color: #001931 !important;
        font-size: 16px !important;
        font-weight: 600 !important;
    }

    .ccb-wrapper-17893 .calc-container .calc-list .calc-subtotal-list .sub-list-item.total .ccb-discount {
        color: #001931 !important;
        font-size: calc(16px - 2px) !important;
        font-weight: 500 !important;
    }

    .ccb-wrapper-17893 .ccb-send-quote__wrapper {
        background-color: #ffffff !important;
    }

    .ccb-wrapper-17893 .ccb-send-quote__wrapper .ccb-send-quote__title,
    .ccb-wrapper-17893 .ccb-send-quote__wrapper label {
        color: #001931 !important;
    }

    .ccb-wrapper-17893 .ccb-send-quote__wrapper .ccb-send-quote__submit button,
    .ccb-wrapper-17893 .ccb-send-quote__success .ccb-send-quote__success-btn {
        background: #00B163 !important;
        color: #F7F7F7 !important;
        border-radius: 4px !important;
    }

    .ccb-wrapper-17893 .ccb-send-quote__success .ccb-send-quote__success-icon {
        color: #00B163 !important;
        background: #00B1631A !important;
    }

    .ccb-wrapper-17893 .ccb-send-quote__success .ccb-send-quote__success-text {
        color: #001931 !important;
    }

    .ccb-wrapper-17893 .ccb-send-quote__wrapper .ccb-send-quote__file {
        border-radius: 4px !important;
    }

    .ccb-wrapper-17893 .ccb-send-quote__wrapper .ccb-send-quote__input input,
    .ccb-wrapper-17893 .ccb-send-quote__wrapper .ccb-send-quote__textarea textarea {
        border-radius: 4px !important;
        border: 1px solid #0019311A !important;
        background: #F7F7F7 !important;
        color: #001931 !important;
    }

    .ccb-thank-you-17893 .thank-you-page .thank-you-page__title-box-title {
        font-size: 16px !important;
        font-weight: 600 !important;
    }

    .ccb-thank-you-17893 .thank-you-page .thank-you-page__order span span,
    .ccb-thank-you-17893 .thank-you-page .thank-you-page__title-box-desc {
        font-size: calc(12px - 2px) !important;
        font-weight: 400 !important;
    }

    .ccb-thank-you-17893 .thank-you-page .thank-you-page__title-box-desc {
        word-break: break-word;
    }

    .ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div a span,
    .ccb-thank-you-17893 .thank-you-page .thank-you-page__actions-wrapper div button span {
        font-weight: 600 !important;
        font-size: calc(16px - 2px) !important;
    }

}
</style>
<style>
/*! elementor - v3.23.0 - 05-08-2024 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {
    background-color: #69727d;
    color: #fff
}

.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {
    color: #69727d;
    border: 3px solid;
    background-color: transparent
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {
    margin-top: 8px
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {
    width: 1em;
    height: 1em
}

.elementor-widget-text-editor .elementor-drop-cap {
    float: left;
    text-align: center;
    line-height: 1;
    font-size: 50px
}

.elementor-widget-text-editor .elementor-drop-cap-letter {
    display: inline-block
}
</style>